import React from 'react';

// const get_recurring_block = (
//   is_regular_donor,
//   is_regular_donation,
//   current_step,
//   ootr,
//   one_off_to_recurring,
//   prevStep
// ) => {
//   const period_map = {
//     weekly: 'week',
//     fortnightly: 'fortnight',
//     monthly: 'month'
//   };
//   const donate_form = document.querySelector('.donate-form');
//   const { amounts, period } = ootr;

//   if (
//     is_regular_donor ||
//     is_regular_donation ||
//     current_step !== 4 ||
//     !donate_form
//   ) {
//     return '';
//   }

//   return (
//     <React.Fragment>
//       <div className="border-top mt-4 pt-3 mb-3">
//         Would you consider becoming a regular donor? A small weekly donation
//         helps us to have even more impact on the kaupapa (causes) that you care
//         about.
//       </div>
//       {[amounts.slice(0, 3), amounts.slice(3, 6)].map((rowAmounts, index) => (
//         <div key={'period_donation_row_' + index} className="row mb-3">
//           {rowAmounts.map(amount => (
//             <button
//               onClick={() => {
//                 one_off_to_recurring(amount, period);
//                 prevStep();
//                 donate_form.style.height = '453px';
//               }}
//               className="col btn btn-primary mx-3"
//               key={'period_donation_' + amount}
//             >
//               ${amount} / {period_map[period]}
//             </button>
//           ))}
//         </div>
//       ))}
//     </React.Fragment>
//   );
// };

const Thanks = ({
  amount,
  ootr,
  details: { is_regular_donor },
  shareUrl,
  title,
  thanks_message,
  currentStep,
  one_off_to_recurring,
  prevStep
}) => {
  return (
    <React.Fragment>
      <div className="card-header alert-info">
        <div className="row text-center">
          <div className="col-12">
            <div className="donate-form__summary h5 mb-0 text-uppercase">
              Thanks for your support!
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div
          className="post_donation_thanks"
          dangerouslySetInnerHTML={{ __html: thanks_message }}
        />
        {/* {get_recurring_block(
          is_regular_donor,
          amount.isRegular,
          currentStep,
          ootr,
          one_off_to_recurring,
          prevStep
        )} */}
      </div>
      <div className="donate-form__actions">
        <div className="card-body border-top py-2">
          <div className="row">
            <div className="col-12 text-center">
              <div className="mb-0 donate__summary">Help spread the word</div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-sm-4">
              <a
                className="btn btn-block btn-primary"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
              >
                Facebook{' '}
                <i className="fab fa-facebook-square" aria-hidden="true" />
              </a>
            </div>
            <div className="col-sm-4 text-center">
              <a
                className="btn btn-block btn-primary"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://twitter.com/intent/tweet?text=${title}&url=${shareUrl}`}
              >
                Twitter <i className="fab fa-twitter" aria-hidden="true" />
              </a>
            </div>
            <div className="col-sm-4 text-right">
              <a
                className="btn btn-block btn-primary"
                href={`mailto:?subject=${title}&body=${shareUrl}`}
              >
                Email <i className="fas fa-envelope" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Thanks;
