import Amount from './amount';
import Details from './details';
import Payment from './payment';
import Thanks from './thanks';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Amount,
  Details,
  Payment,
  Thanks
};
