import { init } from '@rematch/core';
import createModel from './create-model';

const createStore = (initialState) => {
  return init({
    models: {
      donate: createModel(initialState)
    }
  });
};

export default createStore;
