import { connect } from 'react-redux';
import App from './app';
// import { save_donation_state } from '../lib/donation_recovery';

// const member_guid_exists = document.cookie.match(/guid=[^;]+/);
// const save_state_fn = member_guid_exists ? save_donation_state : () => {};

const mapState = (state) => {
  // uncomment to re enable donation recovery state save
  /*
  if (state.donate.currentStep < 4) {
    save_state_fn(state.donate);
  }
  */

  return { ...state.donate };
};
const mapDispatch = (dispatch) => ({
  changeCurrentStep: (currentStep) =>
    dispatch.donate.changeCurrentStep(currentStep),
  changeRecaptchaToken: (recaptchaToken) =>
    dispatch.donate.changeRecaptchaToken(recaptchaToken),
  changeStripeClientSecret: (stripeClientSecret) =>
    dispatch.donate.changeStripeClientSecret(stripeClientSecret),
  changeStripeSubscriptionId: (stripeSubscriptionId) =>
    dispatch.donate.changeStripeSubscriptionId(stripeSubscriptionId),
  changeIsCreatingPaymentIntent: (status) =>
    dispatch.donate.changeIsCreatingPaymentIntent(status),
  submitAmount: (amount) => dispatch.donate.submitAmount(amount),
  submitDetails: (details) => dispatch.donate.submitDetails(details),
  change_save_cc: (save_cc) => dispatch.donate.change_save_cc(save_cc),
  update_saved_cc: () => dispatch.donate.update_saved_cc(),
  change_cover_fee: (cover_fee, amount) =>
    dispatch.donate.change_cover_fee({ cover_fee, amount }),
  createPaymentIntent: (stripeToken) =>
    dispatch.donate.createPaymentIntent(stripeToken),
  one_off_to_recurring: (amount, period) =>
    dispatch.donate.one_off_to_recurring({ amount, period }),
  nextStep: () => dispatch.donate.nextStep(),
  prevStep: () => dispatch.donate.prevStep()
});

export default connect(mapState, mapDispatch)(App);
