import { omit, pick, merge } from 'lodash';
import { add_trx_fee, remove_trx_fee } from '../lib/utils';

export const defaultState = {
  amounts: [10, 20, 50, 100, 250, 500],
  currentStep: 1,
  processingPayment: false,
  paymentErrorMessage: '',
  amount: {
    isRegular: false,
    period: 'weekly',
    amount: '',
    customAmount: ''
  },
  details: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: 'NZ',
    updates: true
  },
  save_cc: false,
  update_cc: false,
  show_cover_fee_checkbox: true,
  cover_fee: false,
  stripeClientSecret: '',
  stripeSubscriptionId: '',
  isCreatingPaymentIntent: false,
  recaptchaToken: ''
};

const createModel = (initialState = {}) => ({
  // Use defaultState and override with initialState is passed in
  state: merge(defaultState, initialState),
  reducers: {
    changeCurrentStep: (state, currentStep) => ({ ...state, currentStep }),
    changeRecaptchaToken: (state, recaptchaToken) => ({
      ...state,
      recaptchaToken
    }),
    changeStripeClientSecret: (state, stripeClientSecret) => ({
      ...state,
      stripeClientSecret
    }),
    changeStripeSubscriptionId: (state, stripeSubscriptionId) => ({
      ...state,
      stripeSubscriptionId
    }),
    changeIsCreatingPaymentIntent: (state, status) => ({
      ...state,
      isCreatingPaymentIntent: status
    }),
    submitAmount: (state, amount) => ({ ...state, amount }),
    submitDetails(state, details) {
      return { ...state, details };
    },
    change_save_cc: (state, save_cc) => ({ ...state, save_cc }),
    update_saved_cc: (state) => ({
      ...state,
      save_cc: true,
      update_cc: true,
      details: {
        ...state.details,
        stripe_customer: { ...state.details.stripe_customer, saved_card: null }
      }
    }),
    change_cover_fee: (state, { cover_fee, amount }) =>
      !amount
        ? { ...state, cover_fee }
        : {
            ...state,
            amount: {
              ...state.amount,
              amount: '',
              customAmount: cover_fee
                ? add_trx_fee(amount)
                : remove_trx_fee(amount)
            },
            cover_fee
          },
    nextStep(state) {
      return { ...state, currentStep: state.currentStep + 1 };
    },
    prevStep(state) {
      return { ...state, currentStep: state.currentStep - 1 };
    },
    paymentRequest(state) {
      return { ...state, processingPayment: true, paymentErrorMessage: '' };
    },
    paymentComplete(state, stripe_customer) {
      return {
        ...state,
        details: { ...state.details, stripe_customer },
        processingPayment: false,
        currentStep: state.currentStep + 1,
        update_cc: false
      };
    },
    paymentError(state, paymentErrorMessage) {
      return {
        ...state,
        processingPayment: false,
        paymentErrorMessage
      };
    },
    one_off_to_recurring: (state, { amount, period }) => ({
      ...state,
      amount: {
        ...state.amount,
        amount,
        customAmount: '',
        period,
        isRegular: true
      },
      show_cover_fee_checkbox: false,
      cover_fee: false
    })
  },
  effects: (dispatch) => ({
    async createPaymentIntent(token, rootState) {
      // Wrap entire fn so we can catch any unhandled errors and log them
      const {
        aid,
        url,
        amount,
        details,
        queryStringParameters,
        save_cc,
        update_cc,
        recaptchaToken
      } = rootState.donate;
      // try {
      // dispatch.donate.paymentRequest();
      dispatch.donate.changeIsCreatingPaymentIntent(true);
      const payload = {
        aid,
        url,
        ...pick(
          queryStringParameters,
          'utm_source',
          'utm_medium',
          'utm_campaign',
          'utm_term',
          'utm_content'
        ),
        // Convert to cents for Stripe and round
        period: amount.isRegular ? amount.period : 'one-off',
        amount: Math.round((amount.amount || amount.customAmount) * 100),
        ...omit(details, 'firstName', 'lastName'),
        first_name: details.firstName,
        last_name: details.lastName,
        member_guid: queryStringParameters.member_guid,
        recovery: queryStringParameters.recovery,
        save_cc,
        update_cc,
        recaptcha_token: recaptchaToken
      };

      const response = await fetch('/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // TODO: Tidy this up!
          'X-CSRF-Token': document.head.querySelector("[name='csrf-token']")
            .attributes['content'].value
        },
        credentials: 'same-origin',
        body: JSON.stringify(payload)
      });
      const responseJSON = await response.json();
      if (response.status === 200) {
        if (Object.keys(responseJSON).includes('subscriptionId')) {
          dispatch.donate.changeStripeSubscriptionId(
            responseJSON.subscriptionId
          );
        }
        dispatch.donate.changeStripeClientSecret(responseJSON.clientSecret);
        dispatch.donate.changeIsCreatingPaymentIntent(false);
        // dispatch.donate.paymentComplete(responseJSON.stripe_customer);
        // trackDonation(
        //   payload.aid,
        //   responseJSON.transaction_id,
        //   payload.amount,
        //   payload.period
        // );
      } else if (response.status === 422) {
        // This should only ever be a caught error from Stripe about the card
        // const errorMessages = Object.values(responseJSON).join(', ');
        // dispatch.donate.paymentError(errorMessages);
      } else {
        throw Error(responseJSON);
      }
      // } catch (error) {
      //   bugsnagClient.notify(error, {
      //     severity: 'info',
      //     // Send the redux state, minus tihngs not needed
      //     // TODO: Add user and request
      //     metadata: { 'redux state': omit(rootState, 'thanksMessage') }
      //   });
      //   console.log(error);
      //   // dispatch.donate.paymentError(
      //   //   'An error has occurred processing your request. If this problem continues, please email us at info@actionstation.org.nz'
      //   // );
      // }
    }
  })
});

export default createModel;
