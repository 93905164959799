import 'babel-polyfill';
// Enable fetch on IE11
import 'whatwg-fetch';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import compileInitialState from '../donate/compile-initial-state';
import createStore from '../donate/create-store';
import Container from '../donate/container';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { remove_from_donation_recovery } from '../lib/donation_recovery';
import { offline_donation_buttons } from '../lib/offline_donation';
import { fundraising_thermometer } from '../lib/fundraising_thermometer';

smoothscroll.polyfill();

window.DONATION_RECOVERY = true;

document.addEventListener('DOMContentLoaded', async () => {
  const pageData = JSON.parse(
    document.getElementById('page-data').getAttribute('data')
  );

  const initialState = compileInitialState(pageData, window.location);
  // initialState.testToken = 'tok_visa';

  // if (process.env.NODE_ENV === 'development') {
  //   Object.assign(initialState, {
  //     amounts: [10, 20, 50, 100, 250, 500],
  //     currentStep: 1,
  //     testToken: 'tok_visa',
  //     amount: {
  //       isRegular: false,
  //       period: 'one-off',
  //       amount: 10,
  //       customAmount: ''
  //     },
  //     details: {
  //       firstName: 'Vimal',
  //       lastName: 'Jobanputra',
  //       email: 'vim@noodle.io',
  //       address: '9a Marina Grove',
  //       city: 'Lower Hutt',
  //       postcode: '5010',
  //       phone: '021 0246 9667',
  //       country: 'NZ',
  //       updates: true
  //     }
  //   });
  // }

  const {
    details: { is_regular_donor }
  } = initialState;
  if (is_regular_donor) {
    await remove_from_donation_recovery(initialState);
    window.DONATION_RECOVERY = false;
  }

  const store = createStore(initialState);

  const releaseVersonElement = document.head.querySelector(
    "[name='release-version']"
  );

  const appVersion =
    (releaseVersonElement &&
      releaseVersonElement.attributes['content'].value) ||
    undefined;

  Bugsnag.start({
    apiKey: import.meta.env.VITE_CLIENT_BUGSNAG_API_KEY,
    appVersion,
    enabledReleaseStages: ['staging', 'production'],
    releaseStage:
      import.meta.env.VITE_CLIENT_BUGSNAG_RELEASE_STAGE || process.env.NODE_ENV,
    plugins: [new BugsnagPluginReact(React)]
  });

  const ErrorBoundary = Bugsnag.getPlugin('react');

  const loadingMessageEl = document.getElementById('js-loading-message');
  loadingMessageEl.parentNode.removeChild(loadingMessageEl);
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <Container />
      </Provider>
    </ErrorBoundary>,
    document.getElementById('donate-app')
  );

  offline_donation_buttons(initialState);

  if (pageData.fundraising_goal && pageData.total_fundraised > 0) {
    fundraising_thermometer('fundraising_thermometer', pageData);
  }
});
