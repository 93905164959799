import React from 'react';

// TODO: Replace with classes that are styled in CSS?
const renderStepNumber = (step, currentStep) => {
  const stepClass =
    step < currentStep
      ? 'badge-secondary'
      : step === currentStep
      ? 'badge-info'
      : 'badge-secondary';
  return (
    <span className={`d-none d-sm-inline badge badge-pill mr-1 ${stepClass}`}>
      {step}
    </span>
  );
};

// TODO: Replace with classes that are styled in CSS?
const renderStepHeader = (stepNumber, stepName, currentStep) => {
  return (
    <div
      className={`col-4${
        stepNumber === 2 ? ' border-secondary border-left border-right' : ''
      }`}
    >
      <p
        className={`donate-form__step text-uppercase my-0${
          stepNumber === currentStep ? ' text-info' : ''
        }`}
      >
        {renderStepNumber(stepNumber, currentStep)} {stepName}
      </p>
    </div>
  );
};

const Progress = ({ currentStep }) => {
  return (
    <div className="card-header">
      <div className="row text-center">
        {renderStepHeader(1, 'Amount', currentStep)}
        {renderStepHeader(2, 'Details', currentStep)}
        {renderStepHeader(3, 'Payment', currentStep)}
      </div>
    </div>
  );
};

export default Progress;
