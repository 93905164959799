import { get, pick } from 'lodash';
import {
  queryStringToObject,
  isValidAmount,
  get_user_check,
  get_ootr_info
} from '../lib/utils';

const compileInitialState = (pageData, location) => {
  const initialState = {
    ...pick(
      pageData,
      'aid',
      'title',
      'mobile_excerpt',
      'thanks_message',
      'details',
      'testToken'
    ),
    url: location.toString(),
    shareUrl: location.toString().split('?')[0],
    amounts: pageData.amounts.map((amount) => parseInt(amount)),
    queryStringParameters: queryStringToObject(location.search),
    amount: {},
    is_member: false
  };

  if (
    ['weekly', 'monthly', 'fortnightly'].indexOf(
      initialState.queryStringParameters.period
    ) !== -1
  ) {
    initialState.amount.isRegular = true;
    initialState.amount.period = initialState.queryStringParameters.period;
  }

  const amount =
    initialState.queryStringParameters.amount || pageData.suggested_amount;
  if (amount) {
    const parsedQSAmount = parseInt(amount);

    // If this is one of the preset amounts, use it
    if (initialState.amounts.indexOf(parsedQSAmount) !== -1) {
      initialState.amount.amount = parsedQSAmount;
      initialState.amount.customAmount = '';
      initialState.currentStep = 2;
    } else if (isValidAmount(amount)) {
      initialState.amount.customAmount = amount;
      initialState.amount.amount = '';
      initialState.currentStep = 2;
    }
  }

  // user type: member/non member, check if details are complete
  const user_check = get_user_check(initialState);
  if (isValidAmount(amount) && user_check.details_complete()) {
    initialState.currentStep = 3;
  }

  // get one off to recurring amounts and period from an url param
  const qs_ootr = get(initialState.queryStringParameters, 'ootr');
  const ootr = get_ootr_info(qs_ootr, initialState.amounts);

  return { ...initialState, is_member: user_check.is_member(), ootr };
};

export default compileInitialState;
