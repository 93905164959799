import React from 'react';
import { Formik, Form, Field } from 'formik';
import AmountSummary from '../amount-summary';
import Progress from '../progress';
import { isValidAmount } from '../../lib/utils';

const AmountButton = ({ field, form, amount, checked, change_cover_fee }) => {
  return (
    <div className="col-4" onClick={() => change_cover_fee(false)}>
      <label
        className={`btn btn-block donate-form__amount-button btn-lg${
          checked ? ' btn-info' : ' btn-outline-dark'
        }`}
      >
        ${amount}
        <input
          id={`${field.name}-${amount}`}
          type="radio"
          checked={checked}
          value={amount}
          {...field}
          onChange={() => {
            form.setFieldValue('amount', amount);
            form.setFieldTouched('amount', false);
            form.setFieldValue('customAmount', '');
            form.setFieldTouched('customAmount', false);
          }}
        />
      </label>
    </div>
  );
};

const renderPeriod = (isRegular) => {
  return (
    <div>
      <div className="row border-top">
        <div className="col-12">
          <div className="form-inline mt-2">
            <Field
              name="isRegular"
              id="isRegular"
              className="mr-1 mr-sm-2"
              type="checkbox"
              checked={isRegular}
            />

            <label
              className="form-check-label mr-1 mr-sm-2"
              htmlFor="isRegular"
            >
              Make your donation
            </label>

            <Field
              id="period"
              name="period"
              component="select"
              className="form-control w-auto px-0 px-sm-2"
            >
              <option value="weekly">Weekly</option>
              <option value="fortnightly">Fortnightly</option>
              <option value="monthly">Monthly</option>
            </Field>
          </div>
        </div>
      </div>
    </div>
  );
};

const Amount = ({
  amounts,
  amount,
  details,
  submitAmount,
  currentStep,
  nextStep,
  change_cover_fee
}) => {
  return (
    <div>
      <Formik
        initialValues={amount}
        enableReinitialize={true}
        validate={(values) => {
          // TODO: DRY up
          if (values.amount) {
            return {};
          }
          if (!isValidAmount(values.customAmount)) {
            return {
              customAmount: 'Please enter an amount in dollars'
            };
          }
        }}
        onSubmit={(values) => {
          submitAmount(values);
          nextStep();

          // skip details section if user details complete - a problem if we want to always trigger a post to create payment intent here
          // if (get_user_check({ details }).details_complete()) {
          //   nextStep();
          // }
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleBlur
        }) => {
          const isCustomAmount =
            touched.customAmount && isValidAmount(values.customAmount);
          const isRegular = values.isRegular;
          return (
            <Form noValidate>
              {/* Ideally these would be onel level up in in app.js. But since they require access to form state in this step, we nest them inside the form */}
              <AmountSummary {...values} />
              <Progress currentStep={currentStep} />
              <div className="card-body pb-2">
                {[amounts.slice(0, 3), amounts.slice(3, 6)].map(
                  (rowAmounts, index) => (
                    <div key={index} className="row mb-3">
                      {rowAmounts.map((amount) => (
                        <Field
                          key={amount}
                          name="amount"
                          amount={amount}
                          checked={amount === values.amount}
                          change_cover_fee={change_cover_fee}
                          component={AmountButton}
                        />
                      ))}
                    </div>
                  )
                )}
                <div className="row mb-1">
                  <div className="col-6 col-sm-8 text-right col-form-label pb-0">
                    <label htmlFor="customAmount">Other amount</label>
                  </div>
                  <div className="col-6 col-sm-4 text-right pb-0">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className={`input-group-text${
                            isCustomAmount
                              ? ' bg-info border-info text-white'
                              : ''
                          }`}
                        >
                          $
                        </span>
                      </div>
                      <Field
                        id="customAmount"
                        data-testid="custom-amount-input"
                        type="number"
                        min="0"
                        step="1"
                        name="customAmount"
                        className={`form-control form-control${
                          isCustomAmount ? ' border-info' : ''
                        }`}
                        onClick={() => change_cover_fee(false)}
                        onFocus={() => {
                          setFieldValue('amount', '');
                          setFieldTouched('amount', false);
                          change_cover_fee(false);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          if (isValidAmount(event.target.value)) {
                            setFieldValue(
                              'customAmount',
                              parseInt(event.target.value)
                            );
                          }
                          change_cover_fee(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div data-testid="amount-error" className="col-12 text-right">
                    {touched.customAmount && errors.customAmount && (
                      <p className="text-danger mb-0">{errors.customAmount}</p>
                    )}
                  </div>
                </div>
                {renderPeriod(isRegular)}
              </div>
              <div className="donate-form__actions">
                <div className="card-footer">
                  <div className="row">
                    <div className="col-6 offset-6 col-sm-5 offset-sm-7">
                      <button
                        className="btn btn-success btn-block"
                        type="submit"
                      >
                        Details{' '}
                        <i className="fas fa-arrow-right" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Amount;
