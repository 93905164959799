import React, { useEffect, Component } from 'react';
import steps from './steps';

class App extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.currentStep !== prevProps.currentStep) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  render() {
    const { currentStep, title, mobile_excerpt, changeCurrentStep } =
      this.props;
    return (
      <div>
        <h1 className="h4 mb-3 text-center d-lg-none">{title}</h1>
        <p className="d-lg-none">{mobile_excerpt}</p>

        <div
          className={`card donate-form donate_form_step${currentStep} border shadow`}
        >
          {Object.values(steps).map((StepComponent, index) => {
            const className =
              index === currentStep - 1
                ? 'donate__form'
                : 'donate__form d-none';
            return (
              <div key={index} className={className}>
                <Listen changeCurrentStep={changeCurrentStep} />
                <StepComponent {...this.props} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default App;

function Listen(props) {
  const { changeCurrentStep } = props;

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    const redirectSuccess =
      new URLSearchParams(window.location.search).get('redirect_status') ===
      'succeeded';

    if (clientSecret && redirectSuccess) {
      changeCurrentStep(4);
    }
  }, [changeCurrentStep]);
  return <div />;
}
