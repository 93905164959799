import React, { useState, useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from "@stripe/react-stripe-js";

import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

import AmountSummary from '../amount-summary';
import Progress from '../progress';

export const BootstrapTextControls = {
  base: {
    fontSize: '18px',
    color: '#464a4c',
    fontSmoothing: 'antialiased'
  }
};

const stripePromise = loadStripe(
  import.meta.env.VITE_CLIENT_STRIPE_PUBLISHABLE_API_KEY
);

export default function Payment(props) {
  const {
    isCreatingPaymentIntent,
    stripeClientSecret,
    stripeSubscriptionId,
    currentStep,
    prevStep,
    amount
  } = props;

  const appearance = {
    theme: 'stripe'
  };
  const options = {
    clientSecret: stripeClientSecret,
    appearance
  };

  return (
    <div className="column">
      <AmountSummary {...amount} />
      <Progress currentStep={currentStep} />
      {isCreatingPaymentIntent && (
        <i className="fas fa-circle-notch fa-spin" aria-hidden="true" />
      )}
      {!isCreatingPaymentIntent && stripeClientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            clientSecret={stripeClientSecret}
            subscriptionId={stripeSubscriptionId}
            prevStep={prevStep}
            isCreatingPaymentIntent={isCreatingPaymentIntent}
          />
        </Elements>
      )}
    </div>
  );
}

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { clientSecret, isCreatingPaymentIntent, prevStep } = props;

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          break;
      }
    });
  }, [clientSecret, stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: import.meta.env.VITE_DONATE_DOMAIN + '/donate'
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {!isCreatingPaymentIntent && (
        <div className="m-4">
          <PaymentElement
            id="payment-element"
            options={{
              iconStyle: 'solid',
              style: BootstrapTextControls
            }}
          />
        </div>
      )}
      <div className="donate-form__actions">
        <div className="card-footer">
          <div>
            <div className="row">
              <div className="col-6 col-sm-5">
                <button
                  className="btn btn-outline-dark btn-block"
                  type="button"
                  onClick={prevStep}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" /> Edit
                  details
                </button>
              </div>
              <div className="col-6 col-sm-5 offset-sm-2">
                <button
                  disabled={isLoading || !stripe || !elements}
                  id="submit"
                  className="btn btn-success btn-block"
                >
                  <span id="button-text">
                    {isLoading ? (
                      <div className="spinner" id="spinner" />
                    ) : (
                      'Pay now'
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="ml-4">
          {message}
        </div>
      )}
    </form>
  );
}
