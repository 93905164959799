import React, { useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
//import CountrySelect from './country-select';
import AmountSummary from '../amount-summary';
import Progress from '../progress';
import Reaptcha from 'reaptcha';

const DetailsSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  country: Yup.string().required('Required'),
  updates: Yup.boolean().required('Required')
});

const CustomInputComponent = ({
  field,
  form: { touched, errors },
  label,
  className
}) => {
  let fieldClass = 'form-control form-control-sm';
  if (touched[field.name]) {
    fieldClass += errors[field.name] ? ' is-invalid' : ' is-valid';
  }
  return (
    <div className={`form-group mb-3 ${className}`}>
      <label className="mb-0" htmlFor={field.name}>
        {label}
      </label>
      <input id={field.name} type="text" className={fieldClass} {...field} />
      {touched[field.name] && errors[field.name] && (
        <small className="invalid-feedback position-absolute text-right pr-2">
          {errors[field.name]}
        </small>
      )}
    </div>
  );
};

const Details = ({
  details,
  amount,
  currentStep,
  prevStep,
  nextStep,
  submitDetails,
  createPaymentIntent,
  is_member,
  changeRecaptchaToken
}) => {
  const captchaRef = useRef(null);
  const [verified, setVerified] = useState(false);
  const onVerify = (recaptchaResponse) => {
    changeRecaptchaToken(recaptchaResponse);
    setVerified(true);
  };

  const get_keep_up_checkbox = (updates) => {
    return !is_member ? (
      <div className="row mt-3">
        <div className="col-12">
          <label htmlFor="updates">
            <Field
              checked={updates}
              type="checkbox"
              name="updates"
              id="updates"
            />{' '}
            Keep me up to date with ActionStation's campaigns
          </label>
        </div>
      </div>
    ) : (
      ''
    );
  };

  return (
    <div>
      <Formik
        initialValues={details}
        validationSchema={DetailsSchema}
        onSubmit={(values) => {
          submitDetails(values); // GK: store form field information in state
          createPaymentIntent({ s: 'bs' }); // GK: POST to donations#create_payment_intent and store the response stripe client secret
          nextStep();
        }}
      >
        {({ values }) => (
          <Form noValidate>
            <AmountSummary {...amount} />
            <Progress currentStep={currentStep} />
            <div className="card-body">
              <div className="form-row">
                <Field
                  component={CustomInputComponent}
                  className="col-6"
                  name="firstName"
                  label="First name"
                />
                <Field
                  component={CustomInputComponent}
                  className="col-6"
                  name="lastName"
                  label="Last name"
                />
              </div>
              <div className="form-row mt-1">
                <Field
                  component={CustomInputComponent}
                  className="col-6"
                  name="email"
                  label="Email"
                />
                <Field
                  component={CustomInputComponent}
                  className="col-6"
                  name="phone"
                  label="Phone (optional)"
                />
              </div>
              <Reaptcha
                sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                ref={captchaRef}
                onVerify={onVerify}
              />
              {get_keep_up_checkbox(values.updates)}
            </div>
            <div className="donate-form__actions">
              <div className="card-footer">
                <div className="row">
                  <div className="col-6 col-sm-5">
                    <button
                      className="btn btn-outline-dark btn-block"
                      type="button"
                      onClick={prevStep}
                    >
                      <i className="fas fa-arrow-left" aria-hidden="true" />{' '}
                      Edit amount
                    </button>
                  </div>
                  <div className="col-6 col-sm-5 offset-sm-2">
                    <button
                      className="btn btn-success btn-block"
                      type="submit"
                      disabled={!verified}
                    >
                      Payment{' '}
                      <i className="fas fa-arrow-right" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Details;
