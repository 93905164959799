import React from 'react';
import { isValidAmount } from '../lib/utils';

const AmountSummary = ({ isRegular, period, amount, customAmount }) => {
  // TODO: DRY up
  const amountToUse = amount || customAmount;
  const isValid = isValidAmount(amountToUse);
  return (
    <div className="card-header alert-info">
      <div className="row text-center">
        <div className="col-12">
          <div
            data-testid="amount-summary"
            className="donate-form__summary h5 mb-0 text-uppercase"
          >
            Make a{' '}
            <span className="font-weight-bold">
              {isRegular ? period : 'one-off'}
            </span>{' '}
            donation{' '}
            {isValid && (
              <React.Fragment>
                of <span className="font-weight-bold">${amountToUse}</span>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmountSummary;
