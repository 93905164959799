import $ from 'jquery';
import { remove_from_donation_recovery } from './donation_recovery.js';

export function offline_donation_buttons(state) {
  let recovery_removed = false;

  $('#offline_donation_buttons a').click(async function () {
    if (state.member_guid && !recovery_removed) {
      await remove_from_donation_recovery(state);

      recovery_removed = true;
      window.DONATION_RECOVERY = false;
    }

    const id = 'offline_donation_' + $(this).data('option');
    $(`div.${id}`).slideToggle();
    window.location.hash = '#offline_donation_buttons';
    window.scrollBy(0, -100);
  });

  $('span.secure_form').click(() => window.scrollTo(0, 0));
}
