const dqs = (q) => document.querySelector(q);

export async function fundraising_thermometer(
  container_id,
  { total_fundraised, fundraising_goal: goal }
) {
  const { format } = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD'
  });
  const amount = Math.round(total_fundraised);

  const ui = {
    amount: dqs(`#${container_id} span.amount`),
    goal: dqs(`#${container_id} span.goal`)
  };

  ui.amount.innerHTML = format(amount);
  ui.goal.innerHTML = format(goal);

  const percentage = Math.ceil((amount / goal) * 100);
  dqs(`#${container_id} .progress`).style.width = `${percentage}%`;

  dqs(`#${container_id} .fundraising_thermometer`).style.visibility = 'visible';
}
